<template>
        <layout>
            <router-view/>
        </layout>
    
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
    @import '../style/index.less';
</style>